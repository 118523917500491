.xmm-full-modal .modal-dialog {
  @include calc-dynamic("width", "100vw - 40px");
  @include calc-dynamic("height", "100vh - 84px");
  margin: auto;
  margin-top: 42px;
  max-width: 1400px;
  min-width: 1100px;

}
.xmm-large-header .modal-header {
  border-bottom: 1px solid $Titanium400;
  padding: 0 0 24px 0;
  margin: 24px 24px 0 24px;
  .dropdown.btn-group {
    margin: 0 8px;
  }
}
.xmm-hours-modal,
.xmm-findopcode-modal,
.xmm-validate-opcode-modal {
  .modal-dialog {
    width: 730px;
  }
  &.long-modal .modal-dialog{
    width: 910px;
  }
}
.xmm-show-inherited-rules-modal {
  .modal-dialog {
    width: 900px;
  }
}
.xmm-copy-to-operation-rules-modal {
  .modal-dialog {
    width: 850px;
  }
}
.xmm-copy-to-operation-rules-container {
  height: 250px;
  width: 100%;
  margin: 12px 0 4px 0;
}
.xmm-preview-modal{
  .modal-dialog{
    width: 96%;
    min-width: 1150px;
    max-width: 1750px;
    @include calc-dynamic(height, "100vh - 40px");
    margin: 20px auto;
    .modal-body{
      padding: 16px 48px;
      @include calc-dynamic(height, "100vh - 115px");
      overflow: auto;
    }
  }
}
.xmm-create-vehicle-group-modal {
  .modal-dialog {
    @include calc-dynamic(width, "100vw - 250px");
    margin: auto;
    top: 30px;
    max-width: 1400px;
    min-width: 800px;
  }
  .xmm-manage-opcodes-form {
    width: 100%;
  }
}
.xmm-included-years {
  width: 300px;
  display: inline-flex;
  vertical-align: middle;
}
.xmm-years-options {
  width: 100px;
  .dropdown-menu{
    min-width: 100px;
  }
}
.xmm-modal-help-text {
  font-size: 12px;
  color: $Titanium700;
  margin: 8px 0;
}
.xmm-inline-icon{
  display: inline-block;
  width: 80%;
  vertical-align: top;
  padding: 4px;
}
.xmm-vehicle-group-modal .modal-body {
  display: flex;
  padding: 0;
  margin: 0 24px;
}
.xmm-filter-list {
  position: relative;
  background: $Titanium200;
  border-right: 1px solid $Titanium400;
  width: 280px;
  display: inline-block;
  padding-left: 10px;
  .xmm-input-search{
    width: 250px;
    border-top: 12px solid $Titanium200;
  }
  .list-group{
    margin-top: 8px;
    @include calc-dynamic(height, "100vh - 190px");
    overflow: auto;
    margin-bottom: 0;
  }
  .list-group-item {
    padding: 8px;
    border: none;
    background-color: transparent;
    word-break: break-all;
    &:hover {
      background-color: $Cobalt200;
      cursor: pointer;
    }
    &.active {
      background-color: $Cobalt500;
    }
  }
}
.xmm-manage-opcodes-form {
  @include calc-dynamic(width, "100% - 280px");
  display: inline-block;
  padding: 16px;
}
.xmm-intervals-root {
  @include calc-dynamic(width, "100% - 280px");
  display: inline-block;
  vertical-align: top;
  padding-top: 16px;
  padding-left: 16px;
}
.xmm-intervals-form {
  @include calc-dynamic(max-height, "100vh - 220px");
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 12px;
}
.xmm-manage-vehicle-root {
  @include calc-dynamic(width, "100% - 280px");
  display: inline-block;
  padding: 16px;
}
.xmm-vehicle-groups-root {
  @include calc-dynamic(width, "100% - 280px");
  display: inline-block;
  vertical-align: top;
  padding-left: 16px;
}
.xmm-create-vehicle-root {
  // @include calc-dynamic(width, "100% - 280px");
  width: 100%;
  display: inline-block;
  padding: 16px;
}
.xmm-opaque {
  opacity: 0.3;
}
.xmm-manage-vehicle-form {
  @include calc-dynamic(max-height, "100vh - 220px");
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 12px;
}
.xmm-vehicle-groups-form {
  @include calc-dynamic(max-height, "100vh - 220px");
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 12px;
}
.xmm-vehicles-grid {
  min-height: 140px !important;
}
.xmm-modal-footer{
  margin-top: 16px;
}
.modal-open .modal{
  overflow-x: auto !important;
}
.xmm-help-modal .cx-accordion{
  border: none;
  box-shadow: none;
  margin-bottom: 0;
  .cx-accordion-title{
    font-size: 12px;
    position: relative;
    left: -14px;
  }
}
.xmm-preview-vehicle-form {
  @include calc-dynamic(max-height, "100vh - 480px");
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 12px;
}
.xmm-add-rules-search {
  width: 250px;
  .xmm-add-rules-search-label {
    color: $Titanium700;
    margin: 1px 0px 3px;
  }
}
.xmm-add-labor-rules-vehicles-label {
  color: $Titanium700;
  margin: 1px 0px 3px;
}
// controls the overlay for more than 2 modals
div[role=dialog]:last-child(){
  .modal-backdrop{
    z-index: 1050;
  }
}

.xmm-centered-radios{
  .cx-radios{
    margin-left: 95px;
    margin-bottom: 8px;
  }
}
.xmm-make-modal{
  margin-left: 24px;
}
