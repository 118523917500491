.settings-card-header {
  display: flex;
  align-items: flex-end;
  .header-left {
    flex: 1;
    .svgicon {
      vertical-align: text-bottom;
    }
    .icon-info-outline {
      color: $Cobalt500;
      vertical-align: middle;
    }
  }

  .btn-div {
    height: auto;
    display: flex;
    float: right;
    align-items: flex-end;
    justify-content: space-around;
    padding: 4px 0;
    // border: 1px solid grey;
  }
}
/* style used for cx-fields row */
.setting-table {
  .xmm-row-title {
    color: $Titanium800;
    font-weight: 600;
    display: flex;
    justify-content: flex-end;
    margin-top: 6px;
    text-align: right;
  }
  .icon-info-outline {
    color: $Cobalt500;
    vertical-align: middle;
  }
  /* style used for cx-field */
  .xmm-form-content {
    display: flex;
    align-items: flex-start;
    min-height: 46px;
    margin-bottom: 4px;
    .form-group {
      margin-bottom: 0;
    }
    .xmm-fixed-col {
      width: 120px;
      text-align: -webkit-center;
      text-align: -moz-center;
      border-bottom: 1px solid $Titanium500;
      .xmm-checkbox-container {
        margin-bottom: 12px;
        margin-top: 8px;
        height: 16px;
      }
      &.no-border {
        border-bottom: 0;
      }
      &.left-aligned {
        text-align: left;
      }
      &.xmm-inline-tip {
        width: 130px;
      }
    }
    .xmm-apply-field {
      width: 200px;
    }
    .xmm-default-driving-condition-field {
      width: 120px;
    }
    .xmm-fixed-col + .xmm-fixed-col,
    .xmm-input-col + .xmm-input-col,
    .xmm-input-col ~ .xmm-fixed-col,
    .xmm-fixed-col ~ .xmm-input-col,
    .xmm-apply-field ~ .xmm-fixed-col {
      margin-left: 20px;
    }
    .xmm-input-col {
      width: 300px;
    }
    .help-block {
      text-align: left;
    }
    &.xmm-form-header {
      align-items: flex-end;
      .xmm-input-col,
      .xmm-fixed-col,
      .xmm-apply-field {
        border-bottom: 3px solid $Titanium500;
      }
    }
    .xmm-colspan-label {
      flex: 1 1 100%;
      color: $Titanium700;
      padding-top: 4px;
    }
  }
}
/* style for Operation preview totals */
.xmm-rates-container {
  border: 1px solid lightgrey;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  max-width: 240px;

}
.xmm-rates-content {
  display: flex;
  text-align: left;
  font-size: 12px;
  justify-content: flex-start;
  color: #6D727A;
  min-width: 100px;
  span {
    line-height: 20px;
    width: 100px;
    margin: 6px;
  }
}
