
/**
 * = BULK EDIT Field styles
 * ------------------------------------------------------------------ */
.xmm-bulkedit-btn{
  // width: 130px;
  width: auto;
}
.bulk-edit-form{
  max-height: calc(100vh - 140px);
  overflow-y: auto;
  margin-top: 32px;
}
.xmm-bulk-form-bar{
  display: inline-block;
  width: 100%;
  .xmm-description-msg{
    display: inline-block;
    font-size: 11px;
    color: $Titanium800;
    line-height: 14px;
  }
}
.xmm-bulk-form-row{
  display: flex;
  .form-group{
    width:300px;
    margin-right: 8px;
    margin-bottom: 16px;
  }
  .cx-switch{
    align-items: start;
    margin-top: 4px;
  }
}
.xmm-bulk-form-row-extra-space {
  padding-bottom: 10px;
}
