.dashboard-list-body table.tg{
  width: 100%;
  height: 250px;
  table-layout: fixed;
}
.dashboard-list-body table.tg .hand{
  cursor: pointer;
}
.dashboard-list-body table.tg .hand-disabled{
  cursor: default;
}
.dashboard-list-body table.tg tr.main-row td.tg-second {
  border-bottom: 1px solid $Titanium600;
  border-left: 1px solid $Titanium600;
  font-weight: 700;
  padding: 0;
  color: $Titanium800;
  font-size: 14px;
}
.dashboard-list-body table.tg tr.main-row td.tg-second .notif-number{
  color: $Titanium800;
  font-size: 32px;
  font-weight: 700;
}
.dashboard-list-body table.tg tr.main-row td.tg-second:first-child{
  border-left: 0px;
}
.dashboard-list-body table.tg td.tg-second{
  border-bottom: none;
  font-size: 14px;
  font-weight: 500;
  border-left: 1px solid $Titanium600;
  vertical-align: middle;
  text-align: center;
  .hand span{ 
    color: $Cobalt600
  }
  &.no-border{
    border-left: 0;
  }
}
.dashboard-list-body table.tg td.tg-second:nth-child(even),
.dashboard-list-body table.tg td.tg-second:first-child{
  border-left:none;
}
.dashboard-list-body table.tg td.tg-second .notif-number{
  color: $Titanium800;
  font-size: 13px;
  font-weight: 400;
  border-radius: 2px;
  border: 1px solid transparent;
  padding: 8px 0;
  width: 80px;
  margin: auto;
}
.dashboard-list-body table.tg td.tg-second .notif-number .tg-bold{
  font-size: 32px;
  font-weight: 700;
  color: $Titanium800;
}
.dashboard-list-body table.tg td.tg-second .notif-number.hand:hover {
  border: 1px solid $Cobalt600;
  background-color: $Cobalt200;
}
.dashboard-list-body table.tg td.tg-second .notif-number.hand-disabled .tg-bold{
  color: $Titanium700;
}