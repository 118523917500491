    /*CX Titanium Colors
*******************************************/

$black: #000000;
$Titanium800: #16171A;
$Titanium700: #6D727A;
$Titanium600: #B2B6BF;
$Titanium500: #CACFD9;
$Titanium400: #E4E9EF;
$Titanium300: #F5F6F7;
$Titanium200: #FAFAFA;
$white: #FFF;

/*CX Cobalt Colors
*******************************************/
$Cobalt800: #0D2245;
$Cobalt700: #173A78;
$Cobalt600: #2053AB;
$Cobalt500: #2B6BDD;
$Cobalt400: #639CFF;
$Cobalt300: #96BDFF;
$Cobalt200: #E3EDFF;
$CobaltE: #33BBFF;
$CobaltE2: #08EFFA;

/*CX Krypton Colors
*******************************************/
$Krypton800: #1A5915;
$Krypton700: #298C20;
$Krypton600: #31A626;
$Krypton500: #38BF2C;
$Krypton400: #62D957;
$Krypton300: #83F279;
$Krypton200: #C2FFBD;
$KryptonE: #33FFBB;
$KryptonE2: #77FF33;

/*CX Giallo Colors
*******************************************/
$Giallo800: #E69100;
$Giallo700: #FFAA00;
$Giallo600: #FCC63F;
$Giallo500: #FFDD00;
$Giallo400: #FCE135;
$Giallo300: #FCE765;
$Giallo200: #FFF5B3;
$GialloE: #FFEC00;
$GialloE2: #F3FF00;

/*CX Sunset Colors
*******************************************/
$Sunset800: #923A00;
$Sunset700: #CC4F10;
$Sunset600: #E65A15;
$Sunset500: #FF6A1D;
$Sunset400: #FF8649;
$Sunset300: #FFA87D;
$Sunset200: #FFD6C2;
$SunsetE: #FF7733;
$SunsetE2: #FFBB33;

/*CX Ferrari Colors
*******************************************/
$Ferrari800: #781717;
$Ferrari700: #A61F1F;
$Ferrari600: #C42525;
$Ferrari500: #DD2B2B;
$Ferrari400: #FA3C3C;
$Ferrari300: #FF7575;
$Ferrari200: #FFCCCC;
$FerrariE: #FF3377;
$FerrariE2: #FF33FF;

/*CX Haze Colors
*******************************************/
$Haze800: #462C78;
$Haze700: #593999;
$Haze600: #6C45BA;
$Haze500: #7F51DB;
$Haze400: #9665F7;
$Haze300: #B18AFF;
$Haze200: #D6C2FF;
$HazeE: #7733FF;
$Haze2: #C800FF;
