.xmm-manage-vehicle.panel {
  margin: 0;
  padding: 0;
  .xmm-vehicle-groups-root {
    padding: 16px;
  }
  .xmm-vehicle-group-form {
    @include calc-dynamic(height, "100vh - 250px");
    overflow-y: overlay;
    overflow-x: hidden;
    padding-right: 14px;
  }
  .xmm-filter-list .list-group{
    @include calc-dynamic(height, "100vh - 230px");
  }
}
