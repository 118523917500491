.cx-drawer__panel {
  max-width: 1300px;
  z-index: 10;
  background-color: $Titanium200;
}
.cx-drawer__background {
  z-index: 4;
}
.xmm-flex-slider .cx-drawer__panel {
  min-width: 930px;
  h3{
    @include calc-dynamic(width, "100% - 190px");
  }
}

.xmm-tabs-buttons {
  .btn {
    margin: 0 8px;
  }
}

// this class places the slider under the header bar
.xmm-slider-header {
  .cx-drawer__panel {
    padding-top: 56px;
  }
  .cx-drawer__close-btn {
    top: 52px;
  }
  &.xmm-hide-bg .cx-drawer__background {
    background-color: transparent;
    pointer-events: none;
  }
}
.xmm-slider-expand-button {
  position: absolute;
  top: 16px;
  right: 52px;
  border-right: 1px solid $Titanium500;
  .svgicon {
    fill: $Titanium700;
    margin-right: 8px;
    cursor: pointer;
  }
}
.xmm-expanded-slider .cx-drawer__panel {
  max-width: none;
  width: 100% !important;
}
