/**
 * = Masking block styles
 * ------------------------------------------------------------------ */

.xmm-mask-container {
  position: relative;
  display: grid;
  margin-bottom: 8px;
}
.xmm-loading-mask {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $white;
  opacity: 0.5;
  z-index: 999;
  cursor: not-allowed;
}
.xmm-loading-mask .xmm-loading-label {
  font-size: 18px;
}

.xmm-mask::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: block;
  background: $white;
  opacity: 0.5;
  z-index: 999;
  cursor: not-allowed;
}
/* Message block styles */
.xmm-loader-msg{
    font-size: 15px;
    color: $Titanium700;
}

.xmm-loader-findopcodes-norows-msg {
  font-size: 15px;
  float: left;
  color: $Titanium700;
}
