.table-container {
  height: 360px;
  width: 99%;
  overflow-y: auto;
  overflow-x: hidden;
}
.plain-container {
  height: auto;
  width: 95%;
  overflow-y: auto;
  overflow-x: hidden;
}
.ant-table-thead > tr > th {
  background: transparent;
  transition: background 0.3s ease;
  text-align: left;
  color: $Titanium700;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 0 8px;
}
.ant-table-row > td:last-child {
  border-right: 1px solid $Titanium500;
}
.ant-table-row > td {
  border-top: 1px solid $Titanium500;
  background-color: $white;
  padding: 4px 8px;
  font-size: 12px;
}
.ant-table-row > td:first-child {
  border-left: 1px solid $Titanium500;
}
.ant-table-row:last-child > td {
  border-bottom: 1px solid $Titanium500;
}
