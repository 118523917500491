/*Checkboxes styles
==============================================*/
/* The container */
.xmm-checkbox-container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 4px;
  &.xmm-horizontal-form{
    align-items: flex-start;
    flex-direction: row;
  }
}

/* Hide the browser's default checkbox */
.xmm-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 16px;
  width: 16px;
  z-index: 1;
  margin: 0;
}
.xmm-checkbox-container .label-checkbox {
  padding: 0 8px 0 24px;
  // max-width: 220px;
  word-break: break-word;
  display: inline-block;
  vertical-align: bottom;
  &.full-label {
    width: auto;
    max-width: inherit;
  }
}
.xmm-checkbox-container.has-error .xmm-checkmark{
  border-color: $Ferrari500;
  border-width: 2px;
}
/* Create a custom checkbox */
.xmm-checkmark {
  position: absolute;
  height: 16px;
  width: 16px;
  background-color: $white;
  border: 1px solid $Titanium600;
  border-radius: 4px;
}

/* When the checkbox is checked, add a blue background */
.xmm-checkbox-container input:checked ~ .xmm-checkmark {
  background-color: $Cobalt500;
  border: 1px solid $Cobalt500;
}
.xmm-checkbox-container input[disabled]:checked ~ .xmm-checkmark {
  background-color: #cccccc;
  border: 1px solid #cccccc;
}

/* Create the checkmark/indicator (hidden when not checked) */
.xmm-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.xmm-checkbox-container-hidden {
  display: none;
}

/* Show the checkmark when checked */
.xmm-checkbox-container input:checked ~ .xmm-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.xmm-checkbox-container .xmm-checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border-bottom: 2px solid $white;
  border-right: 2px solid $white;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
