/**
 * = Vehicle Card styles
 * ------------------------------------------------------------------ */
.xmm-vehicle-header {
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid $Titanium400;
  margin-bottom: 12px;
  .flex-item {
    flex-grow: 1;
    padding-left: 12px;
  }
  .xmm-toggle-col {
    width: 190px;
    margin-right: 16px;
    min-width: 190px;
    .btn-group {
      margin-bottom: 20px;
    }
  }
  .flex-top{
    align-self: flex-start;
    margin-top: 8px;
  }
}
.xmm-vehicle-full-col {
  width: 100%;
  height: 100px;
}
.xmm-vehicle-box,
.xmm-vehicle-box-hover {
  display: flex;
  padding: 4px;
  border: 1px solid $Titanium500;
  border-top: 0;
}
.xmm-vehicle-box-selected {
  display: flex;
  border: 1px solid $Titanium500;
  outline: 4px solid $Cobalt500;
  outline-offset: -5px;
  padding: 4px;
}
.xmm-vehicle-card.xmm-vehicle-card-hover {
  background-color: $Titanium200;
}
.xmm-vehicle-card {
  height: 92px;
  width: 100%;
  display: flex;
  background-color: $white;
  align-items: center;
  justify-content: flex-start;
  .vehicle-image {
    height: 92px;
    width: 121px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-image: url("../images/mock-vehicle.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: $white;
    margin-left: 16px;
    margin-right: 16px;
    mix-blend-mode: multiply;
  }
  .vehicle-img {
    width: 121px;
    margin-left: 16px;
    margin-right: 16px;
    mix-blend-mode: multiply;
  }
  .xmm-icon-col {
    margin-left: 12px;
    .svgicon {
      fill: $Cobalt500;
      cursor: pointer;
    }
  }
  &.xmm-fav-card {
    cursor: pointer;
    .xmm-icon-col {
      height: 75%;
      display: flex;
      border-left: 1px solid $Titanium500;
      padding: 0px 2px 0px 5px;
    }
    .xmm-vehicle-description {
      width: 100%;
      padding-left: 8px;
      flex: 1;
    }
  }
}

.xmm-count-msg {
  display: flex;
  min-width: 260px;
  h1 {
    text-align: center;
  }
  h5 {
    margin: 12px;
  }
}
.xmm-link-caret {
  color: inherit;
  display: inline-block;
  border-width: 0 2px 2px 0;
  border-style: solid;
  width: 8px;
  height: 8px;
  margin: 0px 2px 2px 8px;
  @include transform(rotate(45deg));
  border-color: $Cobalt500;
}
.xmm-float-section {
  position: absolute;
  background: $white;
  z-index: 10;
  @include border-radius(4px);
  @include calc-dynamic(width, "100% - 96px");
  .xmm-vehicle-preview-form {
    height: 575px;
    &.xmm-content-border {
      border: 0px solid $Titanium600;
      padding: 16px 24px;
      margin-bottom: 8px;
      @include border-radius(4px);
      .xmm-tab-right-content {
        top: 16px;
        right: 24px;
      }
    }
    .cx-tabs {
      margin-top: 16px;
    }
  }
}
/* shadow style added to change vehicle container */
.xmm-box-shadow {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.8);
}
/* style container with shadow background */
.xmm-box-effect {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.xmm-box-effect:before,
.xmm-box-effect:after {
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);

  -moz-border-radius: 100px / 10px;
  border-radius: 100px / 10px;
}

.xmm-box-grey {
  background: #cccccc;
}
.xmm-box-white {
  background-color: $white;
}
.xmm-box-padding {
  padding: 2px 15px 2px 15px;
}
.btn.btn-published {
  background-color: $Krypton800;
  color: $white;
  &:hover,
  &:focus,
  &.focus {
    color: $white;
  }
}
.xmm-menu-tabs-content {
  height: calc(100vh - 310px);
  overflow-y: auto;
  overflow-x: hidden;
  overflow: overlay;
  padding-right: 20px;
  position: relative;
}
.xmm-tab-right-content {
  position: absolute;
  top: 0;
  right: 0;
  height: 60px;
  background: $white;
  padding: 2px 5px 0px 20px;
}
.xmm-no-favorites {
  font-style: bold;
  font-size: 14pt;
  pointer-events: none; //This makes it not clickable
}
.xmm-favorite-note {
  font-style: italic;
  font-size: 12pt;
  pointer-events: none; //This makes it not clickable
}

/**
 * = Common vehicles, Favorites block styles
 * ------------------------------------------------------------------ */
.xmm-float-container {
  height: 402px;
  overflow-y: auto;
  overflow-x: hidden;
}
.xmm-common-vehicles {
  display: flex;
  background-color: $white;
  flex-wrap: wrap;
  border-top: 1px solid $Titanium500;
  :nth-child(even) .xmm-vehicle-box,
  :nth-child(even) .xmm-vehicle-box-hover {
    border-left: 0px;
  }
}
.vehicle-content {
  min-width: 50%;
  max-width: 50%;
}

.xmm-menus-header {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: flex-start;
  .icon-selectall {
    padding: 0 16px;
    width: 45px;
  }
  .xmm-icon-col {
    padding-left: 16px;
    span {
      padding-left: 16px;
    }
  }
}
.xmm-icon-size {
  width: 25px;
  height: 10px;
  font-size: 24px;
}

/* page masking with svg  */
@keyframes pageFadeIn {
  0% {
    @include opacity(0.3);
  }
  50% {
    @include opacity(1);
  }
  100% {
    @include opacity(0.3);
  }
}

/**
 * = Preview Masking styles
 * ------------------------------------------------------------------ */
.xmm-mask-preview {
  width: 100%;
  display: block;
  cursor: not-allowed;
  animation: pageFadeIn 5s infinite;
  @include calc-dynamic(height, "100vh - 260px");
  overflow: hidden;
}

.xmm-mask-chart-container {
  border: 0px solid;
  width: 100%;
  height: 360px;
  display: flex;
  background-color: white;
  justify-content: space-around;
  align-items: stretch;
  background-image: url("../images/mock-chart.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 20px;
  overflow: hidden;
}

.xmm-mask-content-container {
  border: 0px solid;
  width: 100%;
  height: calc(100vh - 310px);
  display: flex;
  background-color: white;
  justify-content: space-around;
  align-items: stretch;
  background-image: url("../images/mock-table.svg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top;
  margin-top: 20px;
  overflow: hidden;
}

.xmm-flexbox-right {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  align-self: flex-end;
  align-items: flex-end;
  padding-right: 16px;
}

.xmm-cps-icon {
  display: flex;
  justify-content: space-around;
  float: left;
  width: 20px;
  height: 20px;
  background-color: transparent;
  background-image: url("../images/cps-icon-24x24.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  overflow: hidden;
}
.xmm-dop-icon {
  display: flex;
  justify-content: space-around;
  float: left;
  width: 20px;
  height: 20px;
  background-color: transparent;
  background-image: url("../images/dop-icon-24x24.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  overflow: hidden;
}

/**
 * = Menus inspections styles
 * ------------------------------------------------------------------ */
.xmm-inspections-header {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: auto;
  span {
    padding-left: 14px;
  }
}
.xmm-inspections-service {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: space-around;
  padding: 8px;
}
.xmm-inspections-badge {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-around;
  align-content: flex-start;
  padding: 4px;
  span {
    padding: 4px;
    text-align: center;
    min-width: 45px;
    max-width: 100px;
    &:last-child {
      padding-right: 4px;
    }
  }
}
.xmm-inspections-icons {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
  justify-content: space-around;
  align-content: flex-end;
  padding: 4px;
}

/* Menutypes header row styles */
.xmm-menu-names-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 8px;
  span {
    padding: 8px;
    text-align: center;
    min-width: 120px;
    max-width: 197px;
    &:last-child {
      padding-right: 12px;
    }
  }
}

/* Menu service row styles */
.xmm-service-icons {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-end;
  align-content: center;
  padding: 8px;
  border: 0px solid $black;
  span {
    padding: 8px;
    min-width: 125px;
    max-width: 197px;
    &:last-child {
      padding-right: 10px;
    }
  }
}
/* Menus Expandable Table styles */
.xmm-menus-child {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: flex-start;
  align-content: space-around;
  flex-direction: column;
}
.xmm-service-labor {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
  span {
    padding: 8px;
    min-width: 150px;
    max-width: 197px;
    &:last-child {
      padding-right: 8px;
      min-width: 200px;
    }
  }
}
.xmm-service-parts {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  span {
    padding: 8px;
    min-width: 120px;
  }
  .parts-block {
    width: auto;
    padding: 8px;
    border: 1px solid $black;
  }
}

/**
 * = Menus Table Container styles
 * ------------------------------------------------------------------ */
.component-header {
  display: flex;
  align-items: flex-end;
  height: 60px;
  background-color: $white;
  .header-left {
    flex: 1;
    display: flex;
    align-items: flex-end;
    min-width: fit-content;
    justify-content: space-between;
    padding: 6px 0;
    border-bottom: 1px solid $Titanium500;
    #expandAll {
      margin-right: 12px;
      padding: 0 2px;
    }
    #mileageFilter {
      display: inline-block;
      margin-right: 12px;
      min-width: 240px;
      .form-group {
        margin-bottom: 0;
      }
    }
    .icon-col {
      padding-left: 16px;
      span {
        padding-left: 16px;
      }
    }
  }
  .package-header {
    width: 180px;
    margin-left: 24px;
    text-align: center;
    padding: 4px 0;
    font-weight: 500;
    @include bp-medium {
      width: 120px;
    }
  }
}

.xmm-row {
  display: flex;
  overflow: hidden;
  &.xmm-row-expanded {
    height: auto;
    @include transition(scaleY(1));
    @include transform-origin(top);
    @include transition(transform 0.4s ease-in-out);
  }
  &.xmm-row-hide {
    height: 0;
    @include transition(scaleY(0));
  }
  .xmm-left-header {
    flex: 1;
    padding: 6px 0 3px 0;
    align-items: center;
    display: flex;
    border-bottom: 1px solid $Titanium500;
    cursor: pointer;
    .svgicon {
      margin: 0 8px;
    }
    &.xmm-inspections,
    &.no-border {
      border-bottom: 0;
    }
  }
  .xmm-desc-label {
    flex: 1;
    text-align: right;
    border-bottom: 1px solid $Titanium500;
    font-size: 12px;
    line-height: 20px;
    &.left-aligned {
      text-align: left;
      padding-left: 42px;
      border-bottom: 0;
    }
  }
  .xmm-services {
    display: flex;
    .xmm-total-labels {
      width: 180px;
      margin-left: 24px;
      display: table;
      text-align: center;
      border-bottom: 1px solid $Titanium500;
      @include bp-medium {
        width: 120px;
      }
      .total-labels {
        text-align: right;
        width: 75%;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        margin: 0px;
      }
      .total-values {
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        display: inline-flex;
        text-align: right;
        max-width: 180px;
        justify-content: flex-end;
        overflow: hidden;
        span {
          display: contents;
        }
        @include bp-medium {
          max-width: 120px;
        }
      }

      .menu-price {
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        padding: 8px;
      }
      .badge {
        margin-top: 8px;
      }
    }
  }
}

.op-table {
  .op-row {
    max-height: 40px;
    display: flex;
    overflow: hidden;
    @include transition(max-height 0.4s ease);
    &.modified {
      max-height: 500px;
    }
    &:hover {
      background-color: $Titanium200;
    }

    .op-name {
      flex: 1;
      border-bottom: 1px solid $Titanium500;
      align-items: flex-end;
      justify-content: space-between;
      padding: 4px 0;
      .top-level {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
      }
      .op-icon {
        margin: 0 8px;
        min-width: 24px;
        cursor: pointer;
      }
      .op-title {
        line-height: 35px;
        flex: 1;
        display: grid;
        // dynamic min-width, max-width will be updated in UI
      }
      .op-price {
        width: 100px;
        text-align: right;
        padding-right: 4px;
        .svgicon {
          vertical-align: bottom;
        }
      }
      // Add two columns for Individual operations table
      .op-opcode {
        width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .op-consumer {
        width: 100px;
      }
    }
    .op-services {
      display: flex;
    }
    .op-status {
      width: 180px;
      margin-left: 24px;
      text-align: center;
      padding: 9px 0;
      font-weight: 500;
      border-bottom: 1px solid $Titanium500;
      @include bp-medium {
        width: 120px;
      }
    }
  }
  &.xmm-alacarte-table {
    @include calc-dynamic(height, "100vh - 360px");
    overflow: overlay;
    padding-right: 20px;
    &.xmm-modal-alacarte-table {
      @include calc-dynamic(height, "100vh - 380px");
    }
  }
}

/**
 * = Parts Table styles
 * ------------------------------------------------------------------ */
.table-parts {
  width: 100%;
  font-size: 12px;
  margin: 4px 4px 4px 36px;
  max-width: 650px;
  @include bp-medium {
    max-width: 560px;
  }
  tr td {
    padding: 4px;
  }
  td {
    text-align: left;
    &:first-child {
      width: 120px;
      strong {
        padding: 0 8px;
      }
    }
  }

  &.parts-border {
    tr td {
      border-bottom: 1px solid $Titanium500;
      &:first-child {
        border-bottom: 0;
      }
    }
    tr:last-child td {
      border-bottom: 0;
    }
    tr:first-child td {
      color: $Titanium700;
      &:first-child {
        color: $black;
      }
    }
    &:last-child td {
      &:nth-child(2) {
        width: 180px;
      }
      &:nth-last-child(-n + 3) {
        width: 55px;
        text-align: right;
      }
    }
  }
}
.table-parts + .table-parts {
  border-top: 1px solid $Titanium500;
  tr:first-child td {
    padding-top: 8px;
  }
}

/**
 * = Individual Operations Container styles
 * ------------------------------------------------------------------ */
.xmm-alacarte-header {
  height: 54px;
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: center;
  padding: 2px;

  span {
    padding: 6px;
    min-width: 180px;
    max-width: 200px;
    &:last-child {
      padding-right: 6px;
    }
  }
  .icon-selectall {
    padding: 8px 14px;
    width: 45px;
  }
}

.component-header-alacarte {
  height: 48px;
  display: flex;
  align-items: baseline;
  background-color: $white;
  border-bottom: 1px solid $Titanium500;
  span {
    margin-right: 12px;
  }
  .nameFilter__formGroup {
    width: 300px;
  }
  .makeFilter__formGroup {
    width: 200px;
  }
  .xmm-input-search {
    order: 2;
    margin-right: 0;
  }
  .xmm-modal-help-text {
    margin-left: auto;
    order: 2;
  }
  .form-group {
    margin-bottom: 0px;
  }
}
/* DOM Element sticky */
.sticky {
  position: sticky;
  top: 0;
  width: 100%;
}
.component-header-preview{
  height: 75px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: $white;
  border-bottom: 1px solid $Titanium500;
  .filter-section{
    display: inline-flex;
    flex-wrap: wrap;
    gap: 40px;
    align-items: center;
    .searchableSelectInput{
      min-width: 220px;
    }
    .xmm-label{
      margin: 4px 0px;
    }
    .xmm-readonly-label{
      line-height: 2;
      margin: 6px 4px;
      padding-bottom: 8px;
    }
  }
  .info-section{
    display: flex;
    align-items: center;
    .icon-info-outline {
      color: $Cobalt500;
      vertical-align: middle;
    }
  }
}
