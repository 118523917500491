
/**
 * = Preview Chart styles
 * ------------------------------------------------------------------ */
.xmm-chart-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content: stretch;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 320px;
  overflow-x: auto;
  overflow-y: hidden;
}

.chart-tooltip-box {
  width: 100%;
  padding: 4px;
  background-color: $white;
  opacity: 1.0;
  border: 4px solid $gray500;
  box-shadow: 0px 2px 4px rgba(0,0,0,.2);
}

.chart-tooltip-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: stretch;
  padding: 1px;
  background-color: $white;
  .series-icon {
    position: relative;
    top: 2px;
    width: 15px;
    height: 100%;
    vertical-align: middle;
  }
  .series-label {
    position: relative;
    left: 8px;
    width: 80%;
    text-align: left;
  }
  .series-price {
    width: 75px;
    text-align: right;
  }
}

/* styles for chart axes */
.app > .graph-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}
.graph-wrapper > .y-axis-wrapper {
  background: white;
  position: sticky;
  left: 0;
  z-index: 3;
}
.recharts-rectangle{
  cursor: pointer;
  pointer-events: auto;
}
