
/*Set a rem font size with pixel fallback
*******************************************/
@function calculateRem($size) {
  $remSize: $size / 14px;
  @return $remSize * 1rem;
}
@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

/*Create rounded corners
*******************************************/
@mixin border-radius($radius){
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

/*Create top rounded corners
*******************************************/
@mixin top-border-radius($radius){
  -webkit-border-radius: $radius $radius 0 0;
  -moz-border-radius: $radius $radius 0 0;
  -ms-border-radius: $radius $radius 0 0;
  border-radius: $radius $radius 0 0;
}

/*Calc function cross browser
*******************************************/
@mixin calc-dynamic($property, $expression, $important: "") {
  @if $important == "!important" {
    #{$property}: -moz-calc(#{$expression}) !important;
    #{$property}: -o-calc(#{$expression}) !important;
    #{$property}: -webkit-calc(#{$expression}) !important;
    #{$property}: calc(#{$expression}) !important;
  } @else {
    #{$property}: -moz-calc(#{$expression});
    #{$property}: -o-calc(#{$expression});
    #{$property}: -webkit-calc(#{$expression});
    #{$property}: calc(#{$expression});
  }
}

/*Breakpoints for madia queries
*******************************************/
@mixin bp-large {
  @media only screen and (max-width: 60em) {
    @content;
  }
}
@mixin bp-medium {
  @media only screen and (max-width: 1600px) {
    @content;
  }
}
@mixin bp-small {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}

/*Transitions cross browser
*******************************************/
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

/*Transform cross browser
*******************************************/
@mixin transform($transforms) {
	-webkit-transform: $transforms;
	-moz-transform: $transforms;
	-ms-transform: $transforms;
	transform: $transforms;
}

/*Transform-origin cross browser
*******************************************/
@mixin transform-origin ($origin) {
  -webkit-transform-origin: $origin;
  -moz-transform-origin: $origin;
  -ms-transform-origin: $origin;
  transform-origin: $origin;
}

/*Opacity cross browser
*******************************************/
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

/*Placeholder cross browser
*******************************************/
@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}
